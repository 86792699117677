import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import legoImg from "../../images/courses/lego.png";
import javaScriptImg from "../../images/courses/js.png";
import cAlgorithmImg from "../../images/courses/cpp.png";
import aioImg from "../../images/courses/aio.png";
import webDevelopmentImg from "../../images/courses/website.png";
import aiMathImg from "../../images/courses/TheMathematicsofAI.png";
// import schedule1Img from "../../images/news/schedule1.jpg";

import { withTranslation } from "react-i18next";


const courseInfoList = [
	{
		imgSrc: legoImg,
		title: "CoursesDetails_LevelPre_H2",
		description: "CoursesDetails_LevelPre_P",
		level: "Level_Pre",
		duration: "Duration_Pre",
		language: "Language_Pre",
		age: "Age_Pre",
		prerequisite: "-",
		courseDescr: "CoursesDetails_LevelPre_CourseP",
		certification: "CoursesDetails_LevelPre_CertificationP",
		outcomes: [
			"Outcome_Level_Pre_1",
			"Outcome_Level_Pre_2",
			"Outcome_Level_Pre_3",
			"Outcome_Level_Pre_4",
			"Outcome_Level_Pre_5",
		],
		achievements: [
			"Achievement_Level_Pre_1",
		],
	},
	{
		imgSrc: javaScriptImg,
		title: "CoursesDetails_Level1_H2",
		description: "CoursesDetails_Level1_P",
		level: "Level_1",
		duration: "Duration_1",
		language: "Language_1",
		age: "Age_1",
		prerequisite: "Prerequisite_1",
		courseDescr: "CoursesDetails_Level1_CourseP",
		certification: "CoursesDetails_Level1_CertificationP",
		outcomes: [
			"Outcome_Level_1_1",
			"Outcome_Level_1_2",
			"Outcome_Level_1_3",
			"Outcome_Level_1_4",
			"Outcome_Level_1_5",
			"Outcome_Level_1_6",
		],
		achievements: [
			"Achievement_Level_1_1",
		],
	},
	{
		imgSrc: cAlgorithmImg,
		title: "CoursesDetails_Level2_H2",
		description: "CoursesDetails_Level2_P",
		level: "Level_2",
		duration: "Duration_2",
		language: "Language_2",
		age: "Age_2",
		prerequisite: "Prerequisite_2",
		courseDescr: "CoursesDetails_Level2_CourseP",
		certification: "CoursesDetails_Level2_CertificationP",
		outcomes: [
			"Outcome_Level_2_1",
			"Outcome_Level_2_2",
			"Outcome_Level_2_3",
			"Outcome_Level_2_4",
			"Outcome_Level_2_5",
			"Outcome_Level_2_6",
			"Outcome_Level_2_7",
			"Outcome_Level_2_8",
			"Outcome_Level_2_9",
			"Outcome_Level_2_10",
			"Outcome_Level_2_11",
			"Outcome_Level_2_12",
			"Outcome_Level_2_13",
			"Outcome_Level_2_14",
		],
		achievements: [
			"Achievement_Level_2_1",
			"Achievement_Level_2_2",
			"Achievement_Level_2_3",
		],
	},
	{
		imgSrc: aioImg,
		title: "CoursesDetails_Level5_H2",
		description: "CoursesDetails_Level5_P",
		level: "Level_5",
		duration: "Duration_5",
		language: "Language_5",
		age: "Age_5",
		prerequisite: "Prerequisite_5",
		courseDescr: "CoursesDetails_Level5_CourseP",
		certification: "CoursesDetails_Level5_CertificationP",
		outcomes: [
			"Outcome_Level_5_1",
			"Outcome_Level_5_2",
			"Outcome_Level_5_3",
			"Outcome_Level_5_4",
			"Outcome_Level_5_5",
			"Outcome_Level_5_6",
		],
		achievements: [
			"Achievement_Level_5_1",
			"Achievement_Level_5_2",
		],
	},
	{
		imgSrc: webDevelopmentImg,
		title: "CoursesDetails_Level3_H2",
		description: "CoursesDetails_Level3_P",
		level: "Level_3",
		duration: "Duration_3",
		language: "Language_3",
		age: "Age_3",
		prerequisite: "Prerequisite_3",
		courseDescr: "CoursesDetails_Level3_CourseP",
		certification: "CoursesDetails_Level3_CertificationP",
		outcomes: [
			"Outcome_Level_3_1",
			"Outcome_Level_3_2",
			"Outcome_Level_3_3",
			"Outcome_Level_3_4",
			"Outcome_Level_3_5",
		],
		achievements: [
			"Achievement_Level_3_1",
		],
	},
	{
		imgSrc: aiMathImg,
		title: "CoursesDetails_Level6_H2",
		description: "CoursesDetails_Level6_P",
		level: "Level_6",
		duration: "Duration_6",
		language: "Language_6",
		age: "Age_6",
		prerequisite: "-",
		courseDescr: "CoursesDetails_Level6_CourseP",
		certification: "CoursesDetails_Level6_CertificationP",
		outcomes: [
			"Outcome_Level_6_1",
			"Outcome_Level_6_2",
			"Outcome_Level_6_3",
			"Outcome_Level_6_4",
			"Outcome_Level_6_5",
			"Outcome_Level_6_6",
			"Outcome_Level_6_7",
			"Outcome_Level_6_8",
		],
		achievements: [
		],
	},
	{
		imgSrc: '',
		title: "CoursesDetails_Level7_H2",
		description: "CoursesDetails_Level7_P",
		level: "Level_7",
		duration: "Duration_7",
		language: "Language_7",
		age: "Age_7",
		prerequisite: "-",
		courseDescr: "CoursesDetails_Level7_CourseP",
		certification: "CoursesDetails_Level7_CertificationP",
		outcomes: [
			"Outcome_Level_7_1",
			"Outcome_Level_7_2",
			"Outcome_Level_7_3",
			"Outcome_Level_7_4",
			"Outcome_Level_7_5",
			"Outcome_Level_7_6",
			"Outcome_Level_7_7",
			"Outcome_Level_7_8",
		],
		achievements: [
		],
	},
];



class CoursesDetails extends Component {
	componentDidMount() {

	}

	render() {
		const { t } = this.props;
		const { i18n } = this.props;
		const currentLang = i18n.language;
		const imagePath = currentLang === 'zh'
			? require('../../images/courses/course_poster_zh.png')
			: require('../../images/courses/course_poster_eng.png');

		return (
			<>
				<Header />
				<div className="page-content">
					<div className="page-banner ovbl-dark" style={{ backgroundImage: "url(" + bannerImg + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("CoursesDetail_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("CoursesDetail_LinkHome")}</Link>
								</li>
								<li>{t("CoursesDetail_LinkCourses")}</li>
							</ul>
						</div>
					</div>

					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<h1 className="text-start">{t("Schedule_Content_H")}</h1>
										<div className="courses-post">
											<img alt="" src={imagePath} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("CoursesDetails_Our_Courses")}</h1>
										</div>
										{courseInfoList.map((course, index) => (
											<div key={index}>
												<div className="courses-post">
													<div className="ttr-post-media media-effect">
														<Link to="#">
															<img alt="" src={course.imgSrc} />
														</Link>
													</div>
													<div className="ttr-post-info m-b30">
														<div className="ttr-post-title">
															<h2 className="post-title">{t(course.title)}</h2>
														</div>
														<div className="ttr-post-text">
															<p>{t(course.description)}</p>
														</div>
													</div>
												</div>
												<div className="course-overview" id="overview">
													<h4>{t("CoursesDetails_Overview")}</h4>
													<div className="row">
														<div className="col-md-12 col-lg-4">
															<ul className="course-features">
																<li>
																	<i className="ti-stats-up"></i>
																	<span className="label">{t("CoursesDetails_Common_4")}</span>
																	<span className="value">{t(course.level)}</span>
																</li>
																<li>
																	<i className="ti-time"></i>
																	<span className="label">{t("CoursesDetails_Common_3")}</span>
																	<span className="value">{t(course.duration)}</span>
																</li>
																<li>
																	<i className="ti-smallcap"></i>
																	<span className="label">{t("CoursesDetails_Common_5")}</span>
																	<span className="value">{t(course.language)}</span>
																</li>
																<li>
																	<i className="ti-book"></i>
																	<span className="label">{t("CoursesDetails_Common_1")}</span>
																	<span className="value">{t(course.age)}</span>
																</li>
																<li>
																	<i className="ti-book"></i>
																	<span className="label">{t("CoursesDetails_Common_8")}</span>
																	<span className="value">{t(course.prerequisite)}</span>
																</li>
															</ul>
														</div>
														<div className="col-md-12 col-lg-8">
															<h5 className="m-b10">{t("CoursesDetails_CommonCourseDescr")}</h5>
															<p>
																{t(course.courseDescr)}
																{
																	index === 1 ? (
																		<>
																			({t('CoursesDetails_Level1_Link9')}: <a href="https://www.youtube.com/shorts/BJF_Endku28" target="_blank" style={{ textDecoration: 'underline' }} rel="noopener noreferrer">
																				{t('CoursesDetails_Level1_Link1')}
																			</a>,&nbsp;

																			<a href="https://www.youtube.com/watch?v=VaLj_HzUHD0" target="_blank" style={{ textDecoration: 'underline', marginLeft: '5px' }} rel="noopener noreferrer">
																				{t('CoursesDetails_Level1_Link2')}
																			</a>)
																		</>
																	) : null
																}
															</p>
															<h5 className="m-b10">{t("CoursesDetails_CommonCertification")}</h5>
															<p>
																<b style={{ color: "blue" }}>{t(course.certification)}</b>
															</p>
															<h5 className="m-b10">{t("CoursesDetails_CommonOutcomes")}</h5>
															<ul className="list-checked primary">
																{course.outcomes.map((outcome, idx) => (
																	<li key={idx}>{t(outcome)}</li>
																))}
															</ul>
															{
																index < 5 && (
																	<>
																		<h5 className="m-b10">{t("CoursesDetails_CommonStudentAchievements")}</h5>
																		<ul className="list-checked primary">
																			{course.achievements.map((achievement, idx) => (
																				<li key={idx}>
																					{t(achievement)}
																					{
																						index === 1 && idx === 0 && (
																							<>
																								({t('CoursesDetails_Level1_Link9')}: <a
																									href="https://www.youtube.com/watch?v=VkJfuqjIymQ"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link3")}
																								</a>,&nbsp;
																								<a
																									href="/news-2"
																									target="_blank"
																									style={{ textDecoration: "underline", marginLeft: "5px" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link4")}
																								</a>)
																							</>
																						)
																					}
																					{
																						index === 2 && idx === 0 && (
																							<>
																								(<a
																									href="/news-3"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link5")}
																								</a>)
																							</>
																						)
																					}
																					{
																						index === 2 && idx === 1 && (
																							<>
																								(<a
																									href="/news-5"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link5")}
																								</a>)
																							</>
																						)
																					}
																					{
																						index === 2 && idx === 2 && (
																							<>
																								(<a
																									href="https://www.youtube.com/watch?v=sdGNZ1GbyMY"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link5")}
																								</a>)
																							</>
																						)
																					}
																					{
																						index === 3 && idx === 0 && (
																							<>
																								(<a
																									href="/news-3"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link5")}
																								</a>)
																							</>
																						)
																					}
																					{
																						index === 3 && idx === 1 && (
																							<>
																								(<a
																									href="/news-5"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link5")}
																								</a>)
																							</>
																						)
																					}
																					{
																						index === 0 && idx === 0 && (
																							<>
																								(<a
																									href="/news-9"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link5")}
																								</a>)
																							</>
																						)
																					}
																					{
																						index === 4 && idx === 0 && (
																							<>
																								({t('CoursesDetails_Level1_Link9')}: <a
																									href="https://leadingcoding.com.au/raffle"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link6")}
																								</a>,&nbsp;
																								<a
																									href="https://leadingcoding.com.au/mason"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link7")}
																								</a>,&nbsp;
																								<a
																									href="https://leadingcoding.com.au/florence"
																									target="_blank"
																									style={{ textDecoration: "underline" }}
																									rel="noopener noreferrer"
																								>
																									{t("CoursesDetails_Level1_Link8")}
																								</a>)
																							</>
																						)
																					}
																				</li>
																			))}
																		</ul>
																	</>
																)
															}

														</div>
													</div>
												</div>
												<br />
												<br />
												<br />
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default CoursesDetails = withTranslation()(CoursesDetails);
