import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header1 from "../layout/header/header1";

// Elements
import FeatureContent1 from "../elements/feature-content1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import { withTranslation } from "react-i18next";


const content1 = [
	{
		Title: "Contact2_Title_1",
		Text: "Contact2_Text_1",
	},
	{
		Title: "Contact2_Title_2",
		Text: "Contact2_Text_2",
	},
	{
		Title: "Contact2_Title_3",
		Text: "Contact2_Text_3",
	},
	{
		Title: "Contact2_Title_4",
		Text: "Contact2_Text_4",
	},
	{
		Title: "Contact2_Title_5",
		Text: "Contact2_Text_5",
	},
	{
		Title: "Contact2_Title_6",
		Text: "Contact2_Text_6",
	},
	{
		Title: "Contact2_Title_7",
		Text: "Contact2_Text_7",
	},
];

class About1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeItem: "",
		};
		this.itemRefs = [];
	}
	
	componentDidMount() {

	}

	toggleItem = (id) => {
		this.setState((prevState) => ({
			activeItem: prevState.activeItem === id ? "" : id,
		}), () => {
			if (this.state.activeItem === id && this.itemRefs[id]) {
				this.itemRefs[id].scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		});
	}

	render() {
		const { t } = this.props;
		const { activeItem } = this.state;
		
		return (
			<>
				<Header1 />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("About_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("About_LinkHome")}</Link>
								</li>
								<li>{t("About_LinkAbout")}</li>
							</ul>
						</div>
					</div>

					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<FeatureContent1 />
							</div>
							<div className="container"> 
								<div className="row m-lr0 p-t50">
									<div className="col-lg-10 col-md-10 p-lr30">
										<div className="container mt-3">
											<div className="heading-bx left p-r15">
												<h2 className="title-head">{t("Contact2_Title3")}</h2>
											</div>
											<div id="accordion">
												{content1.map((item, index) => (
													<div className="card" key={index} ref={(ref) => this.itemRefs[index] = ref}>
														<div className={`card-header ${activeItem === index ? 'collapsed' : ''}`} onClick={() => this.toggleItem(index)}>
															<h5 className="mb-0">
																<button
																	className={`button-link ${activeItem === index ? 'collapsed' : ''}`}
																	aria-expanded={activeItem === index}
																	aria-controls={`collapse${index}`}
																	style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
																>
																	<p>{t(item.Title)}</p>
																	<i className="bi bi-arrow-down-circle"></i>
																</button>
															</h5>
														</div>
														<div
															id={`collapse${index}`}
															className={`collapse ${activeItem === index ? 'show' : ''}`}
															aria-labelledby={`heading${index}`}
															data-parent="#accordion"
														>
															<div className="card-body">
																<p style={{ whiteSpace: 'pre-line' }}>{t(item.Text)}</p>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default About1 = withTranslation()(About1);
