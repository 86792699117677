import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header1 from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import weChatImg from "../../images/about/wechat.jpg";

import { withTranslation } from "react-i18next";

import axios from "axios";

import { toast } from "react-hot-toast";

import i18next from "i18next";

class Contact2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			contact_number: "",
			gender: "",
			age: "",
			school: "",
			content: "",
		};
		this.itemRefs = [];
	}
	componentDidMount() {

	}

	handleSubmit = async (event) => {
		event.preventDefault();
		try {
			axios.post(
				"https://leadingcoding.com.au/contact-api/public/api/v1/send_email",
				this.state,
				{
					headers: {
						"x-api-key": "Uc.T*>n_#.1}5)r8mlU!Q$SEKson+[",
					},
				}
			);
			await axios.post(
				"https://leadingcoding.com.au/contact-api/public/api/v1/contact",
				this.state,
				{
					headers: {
						"x-api-key": "Uc.T*>n_#.1}5)r8mlU!Q$SEKson+[",
					},
				}
			);
			this.setState({
				name: "",
				email: "",
				contact_number: "",
				gender: "",
				age: "",
				school: "",
				content: "",
			});
			toast.success(i18next.t("Submit_Succeed_Tip"), {
				position: "bottom-center",
				duration: 5000,
			});
		} catch (error) {
			toast.error(i18next.t("Submit_Fail_Tip"), {
				position: "bottom-center",
				duration: 2000,
			});
		}
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<Header1 />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("Contact_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("Contact_LinkHome")}</Link>
								</li>
								<li>{t("Contact_LinkContact")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="page-banner contact-page">
							<div className="container-fuild">
								<div className="row m-lr0">
									<div className="col-lg-6 col-md-6 section-sp2 p-lr30">
										<div className="heading-bx left p-r15">
											<h2 className="title-head">{t("Contact_Registration")}</h2>
										</div>
										<div className={`card-header-step`}>
											<h5 className="mb-0">
												Step 1: Select the Correct Course
											</h5>
										</div>
										<div className="card-body">
											Read <a
												href="/courses-details"
												target="_blank"
												style={{ textDecoration: 'underline', color: 'rgb(51, 126, 187)' }}
												rel="noopener noreferrer"
											>
												<b>2025 Term 2 Course Schedule and Course Details</b></a> to find out the courseintroduction courses for your child
										</div>
										<div className={`card-header-step`}>
											<h5 className="mb-0">
												Step 2: Confirm with Leading Coding Team about Course Price
											</h5>
										</div>
										<div className={`card-header-step`}>
											<h5 className="mb-0">
												Step 3: Submit Required Forms
											</h5>
										</div>
										<div className="card-body">
											Current Students fill <a
												href="https://docs.google.com/forms/d/e/1FAIpQLSdmcqzyqtHyO-vXItvTr6Nyk8zyyjo3ZzVHs0ncEKdUOAyvEw/viewform?usp=dialog"
												target="_blank"
												style={{ textDecoration: 'underline', color: 'rgb(51, 126, 187)' }}
												rel="noopener noreferrer"
											>
												<b>Leading Coding 2025 Term 2 Registration Form (Current Students)</b>
											</a>
											<br />
											New Students fill <a
												href="https://docs.google.com/forms/d/e/1FAIpQLSdiYusy1MPuNtwgz8CZK-Nowh5USX3DhoxUdtD1lt12wCdUYA/viewform?usp=dialog"
												target="_blank"
												style={{ textDecoration: 'underline', color: 'rgb(51, 126, 187)' }}
												rel="noopener noreferrer"
											><b>Leading Coding 2025 Term 2 Registration Form (New Students)</b></a> and Download, Print and Sign <a
												href="/static/pdf/Media_Consent_Form.pdf"
												target="_blank"
												style={{ textDecoration: 'underline', color: 'rgb(51, 126, 187)' }}
												rel="noopener noreferrer"
											><b>Media Consent Form</b></a> (Can be filled in the teaching center and submit later)
										</div>
										<div className={`card-header-step`}>
											<h5 className="mb-0">
												Step 4: Pay the Tuition Fee
											</h5>
										</div>
										<div className="card-body">
											<h5>Online Payment</h5>
											Name: Leading Coding<br />
											BSB: 105 086<br />
											Account: 060 872 640<br />
											<b>Please write your child's name (the same as that in registration form) in the reference</b><br />
											<p></p>
											<h5>In-Person (Cash)</h5>
											Visit <a
												href="https://www.google.com/maps/search/?api=1&query=99+L%27Estrange+St,+Glenunga+SA+5064"
												target="_blank"
												style={{ textDecoration: 'underline', color: 'rgb(51, 126, 187)' }}
												rel="noopener noreferrer"
											><b>99 L'Estrange St, Glenunga SA 5064</b></a> during class hours on Sundays
											<p></p>
											<h5>Early Bird Discount</h5>
											Enrolling by April 3, 2025, to enjoy special discounts. Don't miss this opportunity to save!<br />
											<p></p>
											<b>We will send the receipts to parents' email addresses after April 6th, 2025. Course instructions will be sent to parents' email addresses one week before the start of the next term</b><br />
											<p></p>
											<h5>More details please read <a
												href="/static/pdf/Leading_Coding_Admission_Policy.pdf"
												target="_blank"
												style={{ textDecoration: 'underline', color: 'rgb(51, 126, 187)' }}
												rel="noopener noreferrer"
											>Leading Coding Admission Policy</a> and <a
												href="/static/pdf/Leading_Coding_Privacy_Policy.pdf"
												target="_blank"
												style={{ textDecoration: 'underline', color: 'rgb(51, 126, 187)' }}
												rel="noopener noreferrer"
											>Leading Coding Privacy Policy</a></h5>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 section-sp2 p-lr30">
										<div className="heading-bx left p-r15">
											<h2 className="title-head">{t("Contact_Information")}</h2>
										</div>
										<div className="row placeani">
											<div className="col-lg-3 pull-right">
												<div className="label">
													<h3>{t("Contact_Phone_Label")}</h3>
												</div>
											</div>
											<div className="col-lg-6 pull-right">
												<div className="value">
													<div style={{ fontSize: 28 + "px" }}>
														{t("Contact_Phone_Value")}
													</div>
												</div>
											</div>
										</div>
										<div className="row placeani">
											<div className="col-lg-3 pull-right">
												<div className="label">
													<h3>{t("Contact_Email_Label")}</h3>
												</div>
											</div>
											<div className="col-lg-6 pull-right">
												<div className="value">
													<div style={{ fontSize: 28 + "px" }}>
														{t("Contact_Email_Value")}
													</div>
												</div>
											</div>
										</div>
										<div className="row placeani">
											<div className="col-lg-3 pull-right">
												<div className="label">
													<h3>{t("Contact_Wechat_Label")}</h3>
												</div>
											</div>
											<div className="col-lg-6 pull-right">
												<div className="value">
													<img alt=""
														style={{ width: 100 + "px", height: 100 + "px" }}
														src={weChatImg}
													/>
												</div>
											</div>
										</div>
										<br />
										<br />
										<br />
										<br />
										<br />
										<br />
										<br />
										<form
											className="contact-bx ajax-form"
											onSubmit={this.handleSubmit}
										>
											<div className="heading-bx left p-r15">
												<h2 className="title-head">{t("Contact2_Title1")}</h2>
											</div>
											<div className="row placeani">
												<div className="col-lg-6 ">
													<div className="form-group">
														<div className="input-group">
															<input
																name="name"
																value={this.state.name}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder1")}
																required
																className="form-control valid-character"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="email"
																value={this.state.email}
																onChange={this.handleChange}
																type="email"
																placeholder={t("Contact2_Placeholder2")}
																className="form-control"
																required
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="contact_number"
																value={this.state.contact_number}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder3")}
																required
																className="form-control int-value"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="gender"
																value={this.state.gender}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder4")}
																required
																className="form-control"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="age"
																value={this.state.age}
																onChange={this.handleChange}
																type="number"
																placeholder={t("Contact2_Placeholder6")}
																required
																className="form-control"
																min="0"
																max="100"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="school"
																value={this.state.school}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder7")}
																required
																className="form-control"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-12">
													<div className="form-group">
														<div className="input-group">
															<textarea
																name="content"
																value={this.state.content}
																onChange={this.handleChange}
																rows="4"
																placeholder={t("Contact2_Placeholder5")}
																className="form-control"
																required
															></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12">
													<button
														name="submit"
														type="submit"
														value="Submit"
														className="btn button-md"
													>
														{" "}
														{t("Contact2_SendM")}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</>
		);
	}
}

export default withTranslation()(Contact2);
